import PropTypes from 'prop-types'
import _round from 'lodash/round'
import _clamp from 'lodash/clamp'
import { useRef } from 'react'
import { Tooltip, TooltipTrigger, TooltipContent } from '../../interactives/Tooltip.jsx'


const TickState = {
  FUTURE: 'future',
  NOW: 'now',
  PAST: 'past',
  OVERTIME: 'overtime',
}

export default function SeekableProgressBar({ className, moment, handleNewDuration, readonly }) {
  const percent = moment ? 1 - (moment?.left) / (moment?.total || 1) : 0
  const progressPct = _clamp(_round(percent * 100, 2), 0, 100)
  const overtime = moment?.left <= 0

  const timelineWrapper = useRef()

  return (
    <>
      <div
        className={[
          'relative flex items-end bg-[#161616] w-full h-7 rounded',
        ].join(' ')}
      >
        <div
          ref={timelineWrapper}
          className={['flex w-full h-100 bg-gray-900 rounded-b', className].join(' ')}
        >
          {/* PROGRESS */}
          <div className="relative flex-grow h-3 border-transparent">
            {/* PROGRESS BACKGROUND */}
            <div className="w-full h-full rounded-b-sm bg-gradient-to-r from-blue-500 to-red-600"></div>

            {/* PROGRESS DARK OVERLAY */}
            <div
              style={{ width: `${progressPct}%` }}
              className={[
                'absolute top-0 left-0 bottom-0 rounded-b-sm bg-black/70 transition-all',
                (progressPct < 100 ? ' !rounded-r-none' : ''),
              ].join(' ')}
            ></div>
            {/* PLAYHEAD */}
            <div
              style={{ left: `${progressPct}%` }}
              className={[
                'absolute transform -translate-x-2 -top-4 bottom-0 transition-all rounded-b-sm overflow-hidden text-red-500',
              ].join(' ')}
            >
              <svg width="14" height="37" viewBox="0 0 14 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 5.58114V5.58125C0.500066 5.88753 0.573079 6.1894 0.712996 6.46185C0.852898 6.73428 1.05566 6.96947 1.30452 7.14796C1.30454 7.14798 1.30457 7.148 1.3046 7.14802L5.49366 10.1537L5.49381 10.1538C5.53992 10.1868 5.57749 10.2304 5.60341 10.2809C5.62933 10.3314 5.64285 10.3873 5.64286 10.444C5.64286 10.444 5.64286 10.444 5.64286 10.444V34.5C5.64286 34.8599 5.78584 35.2051 6.04035 35.4596C6.29486 35.7141 6.64005 35.8571 7 35.8571C7.35995 35.8571 7.70514 35.7141 7.95964 35.4596C8.21416 35.2051 8.35714 34.8599 8.35714 34.5V10.444V10.4437C8.35711 10.3869 8.37061 10.3309 8.39653 10.2804C8.42245 10.2299 8.46004 10.1863 8.50619 10.1532L8.5063 10.1531L12.6954 7.14802C12.6954 7.14801 12.6954 7.148 12.6954 7.14799C12.9443 6.96949 13.1471 6.73429 13.287 6.46185C13.4269 6.18939 13.4999 5.88753 13.5 5.58125V5.58114V2.42857C13.5 1.91708 13.2968 1.42654 12.9351 1.06487C12.5735 0.703188 12.0829 0.5 11.5714 0.5H2.42857C2.17531 0.5 1.92452 0.549884 1.69054 0.646804C1.45655 0.743724 1.24395 0.885781 1.06487 1.06487C0.703188 1.42654 0.5 1.91708 0.5 2.42857V5.58114Z" fill="currentColor" stroke="#161616"/>
              </svg>
            </div>

          </div>
        </div>
      </div>
      {/* LAST 5 SECONDS */}
      <div className='flex gap-1 items-end'>
        {[5000, 4000, 3000, 2000, 1000].map((ms, i) => {
          let state = TickState.FUTURE
          if (overtime) state = TickState.OVERTIME
          else if (moment?.left <= ms - 1000) state = TickState.PAST
          else if (moment?.left <= ms) state = TickState.NOW
          const flash = overtime && Math.ceil(moment?.left / 1000) % 2 === 0
          return (
            <Tooltip
              key={'5-sec-' + i}
              disabled={!moment}
            >
              <TooltipTrigger
                className="h-4 w-4 mb-0.5"
              >
                <button
                  // onClick={() => handleNewDuration(moment.total - moment.left + ms)}
                  disabled={true}
                  className={[
                    'h-4 w-4 rounded border-2 transition-all hover:border-white disabled:pointer-events-none',
                    state === TickState.FUTURE ? 'border-gray-900 bg-red-600' : '',
                    state === TickState.NOW ? 'border-white bg-red-600' : '',
                    state === TickState.PAST ? 'border-gray-900 bg-red-600/30' : '',
                    state === TickState.OVERTIME && !flash ? 'border-gray-900 bg-red-600/30' : '',
                    state === TickState.OVERTIME && flash ? 'border-white bg-red-600' : '',
                    state,
                  ].join(' ')}
                  style={flash ? { boxShadow: '#b91c1b 0 0 8px' } : null}
                ></button>
              </TooltipTrigger>
              <TooltipContent>
                Adjust duration to last {ms / 1000} more second{ms != 1000 && 's'}.
              </TooltipContent>
            </Tooltip>
          )
        })}
      </div>
    </>
  )
}

SeekableProgressBar.propTypes = {
  className: PropTypes.string,
  moment: PropTypes.object,
  handleNewDuration: PropTypes.func,
  readonly: PropTypes.bool,
}
