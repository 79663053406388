import PropTypes from 'prop-types'
import { faThumbTack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CueStartMode } from '@rundown-studio/types'

export default function TopStartTimeComponent ({ startMode, text = '', strikethrough = false }) {
  return (
    <p>
      {startMode === CueStartMode.FIXED && <FontAwesomeIcon icon={faThumbTack} size='xs'/>}
      <span className={strikethrough ? 'line-through' : ''}>{text}</span>
      {startMode === CueStartMode.FIXED && !text && ' Hard start'}
    </p>
  )
}

TopStartTimeComponent.propTypes = {
  startMode: PropTypes.string,
  text: PropTypes.string,
  strikethrough: PropTypes.bool,
}
