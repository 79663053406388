import PropTypes from 'prop-types'
import { useAtomValue } from 'jotai'
import { rundownAtom } from '../../store/rundown.store.js'
import { CueRunState } from '@rundown-studio/utils'
import { runnerAtom } from '../../store/runner.store.js'
import { getTimestampByCueIdAtom } from '../../store/timestamps.store.js'

export default function CueItemIndex ({
  cueId,
  className,
  index,
  readonly,
  loading,
  onSetNext,
  onJumpToCue,
}) {
  const rundown = useAtomValue(rundownAtom)
  const runner = useAtomValue(runnerAtom)
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const timestamp = getTimestampByCueId(cueId)

  const isActive = timestamp?.state === CueRunState.CUE_ACTIVE
  let isNext = false
  if (runner) {
    isNext = timestamp?.state === CueRunState.CUE_NEXT
  } else {
    isNext = rundown?.startCueId === cueId
  }

  return (
    <button
      className={[
        'h-full w-full font-mono border-2 border-transparent rounded transition-color focus:outline-none focus:ring',
        (isNext && 'border-white animate-pulse'),
        (loading && 'bg-animated from-transparent to-white/20'),
        (!(readonly || isNext || isActive) && 'hover:border-white/50'),
        className,
      ].join(' ')}
      onClick={onSetNext}
      onDoubleClick={onJumpToCue}
      disabled={readonly || isActive}
    >
      <span>{index}</span>
    </button>
  )
}

CueItemIndex.propTypes = {
  cueId: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  onSetNext: PropTypes.func,
  onJumpToCue: PropTypes.func,
}
