import PropTypes from 'prop-types'
import { formatCalendarDate } from '@rundown-studio/timeutils'

export default function DateInput ({
  className = '',
  date,
  timezone,
  updateDate,
  ...props
}) {
  function onChange (event) {
  }

  const formattedDate = formatCalendarDate(new Date(date), { timezone, format: 'yyyy-MM-dd' })

  return (
    <input
      className={[
        'enabled:cursor-text transition-colors px-1 text-gray-300 font-mono bg-transparent rounded-sm text-left focus:outline-none',
        className,
      ].join(' ')}
      type="date"
      value={formattedDate}
      onChange={onChange}
      {...props}
    />
  )
}

DateInput.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  timezone: PropTypes.string,
  updateDate: PropTypes.func.isRequired,
}
