import PropTypes from 'prop-types'
import EditableHms from './EditableHms'
import { hmsToMilliseconds, millisecondsToHms } from '@rundown-studio/timeutils'

export default function DurationPopoverContent ({
  duration,
  handleDurationChange = () => {},
  text = '',
  disabled = false,
}) {
  return <div className='w-36 flex flex-col gap-2'>
    <EditableHms
      disabled={disabled}
      hms={millisecondsToHms(Math.ceil(duration / 1000) * 1000)}
      onUpdate={({ hours, minutes, seconds }) => handleDurationChange(hmsToMilliseconds({ hours, minutes, seconds }))}
    />
    {text && <p className='text-xs text-white/80'>{text}</p>}
  </div>
}

DurationPopoverContent.propTypes = {
  duration: PropTypes.number.isRequired,
  handleDurationChange: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
}
