import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faArrowsLeftRight, faEllipsis, faEyeSlash, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import EditableInput from '../../EditableInput.jsx'
import { addRundownColumn, deleteRundownColumn, updateRundownColumn } from '../../../firestore.js'
import { useParams, useSearchParams } from 'react-router-dom'
import ContextMenu from '../../ContextMenu.jsx'
import { ACCESS_WRITE, ACCESS_WRITE_COLUMN } from '../../../constants/rundownAccessStates.js'
import { RundownToken } from '../../../axios.js'
import _without from 'lodash/without'
import _clamp from 'lodash/clamp'
import { useSetAtom } from 'jotai'
import { setColumnWidthAtom } from '../../../store/rundown.store'
import {DEFAULT_COLUMN_WIDTH} from '../../../constants/columnWidth.js'
import PropTypes from 'prop-types'

export default function ColumnHeader ({ column = {}, hiddenColumns = [], moveColumn, updateWidth }) {
  const { rundownId } = useParams()
  const [showContext, setShowContext] = useState(false)
  const setColumnWidth = useSetAtom(setColumnWidthAtom)
  const [touched, setTouched] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const hidden = hiddenColumns.includes(column.id)
  const canEdit = RundownToken.access === ACCESS_WRITE

  const params = new URLSearchParams(window.location.search)

  const updateSearchParams = () => {
    // const searchParams = new URLSearchParams(window.location.search)
    let colIds = (searchParams.get('hiddenColumns') || '').split(',').filter(Boolean)
    if (colIds.includes(column.id)) colIds = _without(colIds, column.id)
    else colIds.push(column.id)
    searchParams.set('hiddenColumns', colIds)
    setSearchParams(searchParams)
  }

  const handleItemClick = async (item, secondary) => {
    setShowContext(false)
    switch (item) {
      case 'add_new_column':
        return addRundownColumn(rundownId, { after: column.id })

      case 'content_type':
        return updateRundownColumn(rundownId, column.id, { type: secondary })

      case 'hide_column':
        return updateSearchParams(hidden ? true : false)

      case 'move_left':
        return moveColumn(column.id, 'left')

      case 'move_right':
        return moveColumn(column.id, 'right')

      case 'delete_column':
        return deleteRundownColumn(rundownId, column.id)

      default:
        return console.info(item, secondary)
    }
  }

  const [dividerDragging, setDividerDragging] = useState(false)
  const [dividerPos, setDividerPos] = useState(column.width || DEFAULT_COLUMN_WIDTH)

  const containerElement = useRef()
  const memoizedOnDividerMove = useCallback(onDividerMove, [dividerDragging])
  const memoizedOnDividerUp = useCallback(onDividerUp, [dividerDragging])

  function onDividerDown (event) {
    event.preventDefault()
    setDividerDragging(true)
  }

  function onDividerMove (event) {
    event.preventDefault()
    if(!dividerDragging) return
    setTouched(true)
    const bounding = containerElement?.current.getBoundingClientRect()
    const newPos = (event.clientX) -  bounding.left
    const MIN_POS = 100 // never smaller than 100px
    const MAX_POS = 800 // never bigger than 800px
    setDividerPos(_clamp(newPos, MIN_POS, MAX_POS))
    // setColumnWidth(column.id, _clamp(newPos, MIN_POS, MAX_POS))
  }

  function onDividerUp (event) {
    event.preventDefault()
    setDividerDragging(false)
    setTouched(false)
    setColumnWidth(column.id, containerElement?.current.getBoundingClientRect().width)
    updateWidth(column.id, containerElement?.current.getBoundingClientRect().width)
  }

  useEffect(() => {
    if(dividerDragging) {
      document.addEventListener('mousemove', memoizedOnDividerMove)
      document.addEventListener('mouseup', memoizedOnDividerUp)
    }
    return () => {
      document.removeEventListener('mousemove', memoizedOnDividerMove)
      document.removeEventListener('mouseup', memoizedOnDividerUp)
    }
  }, [dividerDragging])

  useEffect(() => {
    if(!touched) setDividerPos(column.width)
  }, [column.width])

  if (hidden) return null
  return (
    <div
      ref={containerElement}
      style={{
        width: `${dividerPos}px`,
      }}
      className="group relative"
    >
      <div className="relative flex gap-2 border-b-2 border-white/20">
        <EditableInput
          className="flex-1 h-6 text-sm w-[8rem] text-white truncate overflow-ellipsis"
          value={column.name}
          placeholder="Column name"
          onChange={(name) => updateRundownColumn(rundownId, column.id, { name })}
          disabled={!canEdit}
        />
        {canEdit ? (
          <>
            <button
              className="mr-6 flex-none h-6 w-6 opacity-0 group-hover:opacity-30 hover:!opacity-100"
              onClick={(e) => !showContext && setShowContext(e.nativeEvent)}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
            {showContext && (
              <ContextMenu
                openEvent={showContext}
                onClose={() => setShowContext(false)}
                onClick={handleItemClick}
                placement={'top-10 left-1/2'}
                items={[
                  {
                    name: 'Hide column for me',
                    value: 'hide_column',
                    icon: faEyeSlash,
                  },
                  {
                    name: 'Column type...',
                    value: 'content_type',
                    type: 'secondary',
                    options: [
                      {
                        name: 'Richtext',
                        value: 'richtext',
                      },
                      {
                        name: 'Dropdown',
                        value: 'select',
                      },
                    ],
                  },
                  {
                    name: 'Add new column',
                    value: 'add_new_column',
                    icon: faPlus,
                  },
                  {
                    name: 'Move left',
                    value: 'move_left',
                    icon: faArrowLeft,
                  },
                  {
                    name: 'Move right',
                    value: 'move_right',
                    icon: faArrowRight,
                  },
                  {
                    type: 'spacer',
                  },
                  {
                    name: 'Delete column',
                    value: 'delete_column',
                    icon: faTrashAlt,
                  },
                ]}
              />
            )}
          </>
        ) : ''}
        {RundownToken.access === ACCESS_WRITE_COLUMN && params?.get('edit-columns') === column.id ? (
          <div className="px-1 py-0.5 bg-gray-900 text-gray-400 rounded text-xs my-auto">Editable</div>
        ) : ''}
        {/* RESIZER */}
        {RundownToken.access === ACCESS_WRITE &&
        <div
          onMouseDown={onDividerDown}
          className={['absolute right-1 flex-none h-6 w-4 opacity-0 group-hover:opacity-30 hover:!opacity-100 px-1 cursor-col-resize transition-opacity'].join(
            ' ',
          )}
        >
          <FontAwesomeIcon icon={faArrowsLeftRight} />
        </div>
        }
      </div>
      {dividerDragging && <span className='absolute right-0 bg-gray-500 w-0.5 h-screen inline-block'></span>}
    </div>
  )
}

ColumnHeader.propTypes = {
  column: PropTypes.object,
  hiddenColumns: PropTypes.array,
  moveColumn: PropTypes.func,
  updateWidth: PropTypes.func,
}
