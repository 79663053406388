import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react'
import { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import _last from 'lodash/last'

export default function AdvancedSelect ({
  className = '',
  options = [],
  value = undefined,
  setValue,
  placeholder = 'Choose an option...',
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)

  const {x, y, strategy, refs, context} = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(3),
      size({
        apply({rects, elements, availableHeight}) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(200, availableHeight)}px`,
            width: `${rects.reference.width}px`,
          })
        },
        padding: 25,
      }),
      flip({
        padding: 25,
        fallbackStrategy: 'initialPlacement',
      }),
    ],
  })

  const listRef = useRef([])
  const listContentRef = useRef(options.map((opt) => _last(opt.value.split('/'))))
  const isTypingRef = useRef(false)

  const click = useClick(context, {event: 'mousedown'})
  const dismiss = useDismiss(context)
  const role = useRole(context, {role: 'listbox'})
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  })
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange (isTyping) {
      isTypingRef.current = isTyping
    },
  })

  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([
    click,
    dismiss,
    role,
    listNav,
    typeahead,
  ])

  const handleSelect = (index) => {
    setValue(options[index].value)
    setSelectedIndex(index)
    setIsOpen(false)
  }

  const selectedItem = selectedIndex !== null ? options[selectedIndex] : undefined

  useEffect(() => {
    const index = options.findIndex((opt) => opt.value === value)
    setSelectedIndex(index)
  }, [options, value])

  return (
    <div>
      <button
        // Safari VoiceOver cuts off the last letter of the textContent when a native button has role="combobox" :|
        ref={refs.setReference}
        type="button"
        aria-labelledby="select-label"
        aria-autocomplete="none"
        data-open={isOpen ? '' : undefined}
        className={[
          'flex items-center gap-3',
          'rounded bg-gray-800 disabled:opacity-50 h-9 w-full px-3 text-left',
          'hover:enabled:brightness-125 transition-[filter]',
          className,
        ].join(' ')}
        {...getReferenceProps()}
        {...props}
      >
        <span className="grow text-left">
          { selectedItem?.label || placeholder }
        </span>
        <FontAwesomeIcon icon={faChevronDown} size="xs" className="opacity-40" />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            className="relative z-50 max-h-[20rem] overflow-y-auto rounded bg-gray-700 bg-clip-padding p-1 shadow-lg mt-0 text-sm"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {options.map((option, i) => (
              <div
                key={option.value}
                ref={(node) => (listRef.current[i] = node)}
                role="option"
                tabIndex={i === activeIndex ? 0 : -1}
                aria-selected={i === selectedIndex && i === activeIndex}
                className={[
                  'flex cursor-default select-none scroll-my-1 items-center gap-2 rounded px-2 py-1 outline-none text-white',
                  (i === activeIndex ? 'bg-blue-500' : ''),
                ].join(' ')}
                {...getItemProps({
                  // Handle pointer select.
                  onClick() {
                    handleSelect(i)
                  },
                  // Handle keyboard select.
                  onKeyDown(event) {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                      handleSelect(i)
                    }

                    // Only if not using typeahead.
                    if (event.key === ' ' && !isTypingRef.current) {
                      event.preventDefault()
                    }
                  },
                  onKeyUp(event) {
                    if (event.key === ' ' && !isTypingRef.current) {
                      handleSelect(i)
                    }
                  },
                })}
              >
                <div className="shrink-0 w-3 text-center" aria-hidden>
                  {i === selectedIndex && <FontAwesomeIcon icon={faCheck} size="xs" />}
                </div>
                <div className="grow pr-3">
                  <p className="font-semibold">{ option.label }</p>
                  {option.detail && (
                    <p className="opacity-50 font-light">
                      { option.detail }
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}

AdvancedSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    detail: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}
