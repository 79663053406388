import { atom } from 'jotai'

//
// Runner
//
const _runnerAtom = atom(null)
export const runnerAtom = atom(
  (get) => {
    const runner = get(_runnerAtom)

    // If new runner or null, return actual runner
    if (!runner || runner.originalCues) return runner

    return null
  },
  (get, set, runner) => {
    set(_runnerAtom, runner)
  },
)

//
// Ping
//
export const pingAtom = atom(0)
