import PropTypes from 'prop-types'

export default function CueTimingWrapper({
  top,
  center,
  cellStyle,
}) {
  return (
    <div className='w-28 px-2 py-2 flex flex-col justify-center items-center' style={cellStyle}>
      <div className='flex-1 flex flex-col justify-end text-xs text-center text-white/75 tabular-nums'>{top}</div>
      <div className='my-1'>{center}</div>
      <div className='flex-1 flex flex-col justify-end'></div>
    </div>
  )
}

CueTimingWrapper.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])

  ,
  center: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])

  ,
  cellStyle: PropTypes.object,
}
