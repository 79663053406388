import PropTypes from 'prop-types'
import AdvancedSelect from '../../interactives/AdvancedSelect'
import TimeInput from '../TimeInput'
import DateInput from '../DateInput'
import { CueStartMode } from '@rundown-studio/types'

export default function TimePopoverContent ({
  startTime,
  timezone = 'UTC',
  startMode,
  handleStartTimeChange = () => {},
  handleStartModeChange = () => {},
  text = '',
  disabled = false,
  startModeDisabled = false,
}) {
  return (
    <div className='w-64 flex flex-col gap-2'>
      <AdvancedSelect
        className='text-sm !h-7 !px-2'
        disabled={startModeDisabled || disabled}
        value={startMode}
        setValue={handleStartModeChange}
        options={[
          { value: CueStartMode.FLEXIBLE, label: 'Soft Start', detail: 'Flexible start based on previous cues.' },
          { value: CueStartMode.FIXED, label: 'Hard Start (Manual)', detail: 'Start manually at a specified time.' },
        ]}
      />
      <div className='flex pl-1'>
        <label htmlFor="startTime" className='w-16 text-sm leading-7 text-gray-200'>Time: </label>
        <TimeInput
          id="startTime"
          className="!w-full !h-7 !px-2 !rounded text-sm !bg-gray-800 hover:enabled:brightness-125"
          disabled={disabled}
          timezone={timezone}
          time={startTime}
          updateTime={(time) => handleStartTimeChange(time)}
        />
      </div>
      <div className='flex pl-1'>
        <label htmlFor="startDate" className='w-16 text-sm leading-7 text-gray-200'>Date: </label>
        <DateInput
          id="startDate"
          className="!w-full !h-7 !px-2 !rounded text-sm !bg-gray-800 hover:enabled:brightness-125"
          timezone={timezone}
          disabled={true}
          date={startTime}
          updateDate={() => {}}
        />
      </div>
      {text && <p className='text-xs text-white/80'>{text}</p>}
    </div>
  )
}

TimePopoverContent.propTypes = {
  startTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  timezone: PropTypes.string,
  startMode: PropTypes.string,
  handleStartTimeChange: PropTypes.func,
  handleStartModeChange: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  startModeDisabled: PropTypes.bool,
}
