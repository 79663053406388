/**
 * Removes the milliseconds from the given date by flooring them.
 *
 * @param {Date} date - The date from which milliseconds will be removed.
 * @returns {Date} - A new Date object with milliseconds set to 0.
 */
export default function removeMilliseconds(date) {
  const newDate = new Date(date.getTime())
  newDate.setMilliseconds(0)
  return newDate
}
