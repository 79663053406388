import { useState, useEffect, useCallback } from 'react'
import { useParams, Outlet, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAtom, useSetAtom } from 'jotai'
import { cellsAtom, cellsInitAtom, clearRundownAtom, columnsAtom, columnsInitAtom, cuesAtom, cuesInitAtom, rundownAtom } from '../../store/rundown.store.js'
import { runnerAtom } from '../../store/runner.store.js'
import { momentAtom } from '../../store/moment.store.js'
import { timestampsAtom } from '../../store/timestamps.store.js'
import { RundownToken } from '../../axios.js'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import { db } from '../../firebase.js'
import { parseSnapshot } from '@rundown-studio/utils'
import Layout from '../../components/Layout.jsx'
import Button from '../../components/Button.jsx'
import ConnectionChecker from '../../components/rundown/ConnectionChecker.jsx'
import { getRundownEvent, rundownPlan } from '../../firestore.js'
import { planAtom } from '../../store/plan.store.js'
import { clearEventAtom, eventAtom, eventInitAtom } from '../../store/event.store.js'
import { RUNDOWN_COLLECTION, CUE_COLLECTION, CELL_COLLECTION, COLUMN_COLLECTION, RUNNER_COLLECTION } from '@rundown-studio/consts'

let unsubscribers = []

export default function RundownParent () {
  const { rundownId } = useParams()
  const [initialLoadDone, setInitialLoadDone] = useState(false)
  const [error, setError] = useState(null)
  const [rundown, setRundown] = useAtom(rundownAtom)
  const [event, setEvent] = useAtom(eventAtom)
  const [eventInit, setEventInit] = useAtom(eventInitAtom)
  const [plan, setPlan] = useAtom(planAtom)
  const [runner, setRunner] = useAtom(runnerAtom)
  const [cuesInit, setCuesInit] = useAtom(cuesInitAtom)
  const [cues, setCues] = useAtom(cuesAtom)
  const [cellsInit, setCellsInit] = useAtom(cellsInitAtom)
  const [cells, setCells] = useAtom(cellsAtom)
  const [columnsInit, setColumnsInit] = useAtom(columnsInitAtom)
  const [columns, setColumns] = useAtom(columnsAtom)
  const clearRundown = useSetAtom(clearRundownAtom)
  const clearEvent = useSetAtom(clearEventAtom)
  const createMoment = useSetAtom(momentAtom)
  const createTimestamps = useSetAtom(timestampsAtom)

  //
  // Create a 100ms interval if rundown is running
  // Use this interval to create the moment and timestamps
  // If not running, update every minute
  //
  const [counter, setCount] = useState(0)
  if (runner?.timesnap?.running) {
    setTimeout(() => setCount(counter + 1), 100)
  } else {
    setTimeout(() => setCount(counter + 1), 60 * 1000)
  }
  useEffect(() => {
    createMoment()
    createTimestamps()
  }, [initialLoadDone, rundown, runner, cuesInit, cues, counter])

  //
  // Initiate the rundown token
  //
  const requestToken = useCallback(async () => {
    const url = new URL(window.location)
    const signature = url.searchParams.get('signature')
    url.searchParams.delete('signature')
    await RundownToken.requestToken(rundownId, url.href, signature)
  }, [rundownId])

  //
  // Get the payment plan associated with the rundown
  //
  const requestPlan = useCallback(async () => {
    const {data: rundownPlanData} = await rundownPlan(rundownId)
    setPlan(rundownPlanData)
  }, [rundownId])

  //
  // Get the payment plan associated with the rundown
  //
  const requestEvent = useCallback(async (eventId) => {
    if(!eventId) return setEventInit(true)
    const {data: rundownEventData} = await getRundownEvent(rundownId)
    setEvent(rundownEventData)
    setEventInit(true)
  }, [])

  // //
  // // Get the teams colours
  // //
  // const requestTeamColours = useCallback(async (teamId) => {
  //   const writeAccess = RundownToken.access === ACCESS_WRITE
  //   if(!teamId || !writeAccess) return
  //   const {data: teamData} = await getTeam(teamId, true)
  //   setTeamColours(teamData.customColours)
  // }, [rundown?.teamId])

  //
  // Subscribe to Firestore updates on all relevant documents
  // Only resolve when intital payloads are loaded
  //
  const requestData = useCallback(async () => {
    const promises = []

    // Subscribe to rundown
    promises.push(new Promise((resolve) => {
      unsubscribers.push(
        onSnapshot(doc(db, RUNDOWN_COLLECTION, rundownId), async (doc) => {
          if(!doc.exists()) return setError({message: 'This rundown does not exist.'})
          const rundownData = parseSnapshot(doc, { dateKeys: ['startTime', 'endTime']})
          setRundown(rundownData)
          if (rundownData.archivedAt) return setError({ message: 'This rundown is archived.' })
          // requestTeamColours(rundownData.teamId)
          requestEvent(rundownData.eventId)
          resolve()
        }),
      )
    }))

    // Subscribe to cues
    promises.push(new Promise((resolve) => {
      const cuesQuery = query(collection(db, RUNDOWN_COLLECTION, rundownId, CUE_COLLECTION))
      unsubscribers.push(
        onSnapshot(cuesQuery, (querySnapshot) => {
          const cues = []
          querySnapshot.forEach((doc) => {
            cues.push(parseSnapshot(doc, { dateKeys: ['startTime']}))
          })
          setCues(cues)
          setCuesInit(true)
          resolve()
        }),
      )
    }))

    // Subscribe to cells
    promises.push(new Promise((resolve) => {
      const cellsQuery = query(collection(db, RUNDOWN_COLLECTION, rundownId, CELL_COLLECTION))
      unsubscribers.push(
        onSnapshot(cellsQuery, (querySnapshot) => {
          const cells = []
          querySnapshot.forEach((doc) => {
            cells.push(parseSnapshot(doc))
          })
          setCells(cells)
          setCellsInit(true)
          resolve()
        }),
      )
    }))

    // Subscribe to columns
    promises.push(new Promise((resolve) => {
      const columnsQuery = query(collection(db, RUNDOWN_COLLECTION, rundownId, COLUMN_COLLECTION))
      unsubscribers.push(
        onSnapshot(columnsQuery, (querySnapshot) => {
          const columns = []
          querySnapshot.forEach((doc) => {
            columns.push(parseSnapshot(doc))
          })
          setColumns(columns)
          setColumnsInit(true)
          resolve()
        }),
      )
    }))

    await Promise.all(promises)
  }, [rundownId])

  function unsubscribeAll () {
    unsubscribers.forEach((unsub) => unsub())
    unsubscribers = []
    // Clear out rundown and event store
    clearEvent()
    setEventInit(false)
    return clearRundown()
  }

  //
  // Perform the initial load
  //
  const initialLoad = useCallback(async () => {
    try {
      await requestToken()
      await requestData()
      await requestPlan()
    } catch (err) {
      console.error(err)
      setError(err)
    }
  }, [requestToken, requestData, requestPlan])

  useEffect(() => {
    initialLoad()
    return unsubscribeAll
  }, [initialLoad])

  /**
   * Avoid fatal error by resetting initial load when the store unloads during component hot-reloading in development
   */
  if (import.meta.env.DEV) {
    if (initialLoadDone && !rundown) setInitialLoadDone(false)
  }

  //
  // Subscribe to the runner, resubscribe if runnerId changes
  //
  useEffect(() => {
    if (!rundown?.runnerId) return setRunner(null)
    return onSnapshot(doc(db, RUNNER_COLLECTION, rundown?.runnerId), (doc) => {
      setRunner(parseSnapshot(doc))
    })
  }, [rundown?.runnerId])

  //
  // Set initialLoadDone=true once all data is loaded
  //
  useEffect(() => {
    if (initialLoadDone) return
    if (!rundown) return
    if (!plan) return
    if (!cuesInit) return
    if (!cellsInit) return
    if (!columnsInit) return
    if (!eventInit) return
    setInitialLoadDone(true)
  })

  if (initialLoadDone) return (
    <>
      <Outlet
        context={{
          rundown,
          cues,
          cells,
          columns,
          runner,
          plan,
          event,
        }}
      />
      <ConnectionChecker />
    </>
  )
  else if (error) return <RundownError error={error} />
  else return <RundownLoader />
}

function RundownLoader() {
  return <Layout title="Loading..." loading={true} />
}

function RundownError({ error = new Error('Rundown not found') }) {
  const navigate = useNavigate()
  return (
    <Layout title="Error">
      {error?.response?.data || error?.message}
      <Button text="Try logging in" onClick={() => navigate(`/login?next=${window.location.pathname}`)} />
    </Layout>
  )
}

RundownError.propTypes = {
  error: PropTypes.instanceOf(Error),
}
