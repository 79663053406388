import { atom } from 'jotai'
import { createTimestamps, fastDeepEqual } from '@rundown-studio/utils'
import { rundownAtom, filteredAndSortedCuesAtom } from './rundown.store.js'
import { runnerAtom } from './runner.store.js'

/**
 * Private value store
 */
const _timestamps = atom(null)

/**
 * Public interface to timestamps
 * Getter: Get timestamps
 * Setter: Re-creates timestamps, takes no parameters
 */
export const timestampsAtom = atom(
  (get) => get(_timestamps),
  (get, set) => {
    // Collect params
    const cues = _parseCueDates(get(filteredAndSortedCuesAtom))
    const rundown = get(rundownAtom)
    const runner = get(runnerAtom)
    if (!Object.keys(cues).length || !rundown) {
      set(_timestamps, null)
      return
    }

    // Create timestamps
    const newTimestamps = createTimestamps(
      cues,
      rundown.cues,
      runner,
      rundown.startTime,
      {
        timezone: rundown.timezone,
        now: new Date(),
      },
    )

    // Preserve existing object signature if there are no changes
    const oldTimestamps = get(_timestamps)
    const isEqual = fastDeepEqual(newTimestamps, oldTimestamps)
    if (!isEqual) set(_timestamps, newTimestamps)
  },
)

export const getTimestampByCueIdAtom = atom(
  (get) => {
    const timestamps = get(_timestamps)
    return (cueId) => timestamps?.cues[cueId] || null
  },
)

function _parseCueDates (cues) {
  return cues.map((cue) => {
    if (cue.startTime) cue.startTime = new Date(cue.startTime)
    return cue
  })
}
