import { addMilliseconds } from 'date-fns'
import { formatDurationHuman, formatTimeOfDay } from '../../../utils/formatTime.js'
import CurrentTimeOfDay from '../CurrentTimeOfDay.jsx'
import PropTypes from 'prop-types'
import formatTimezone from '../../../utils/formatTimezone.js'
import eventBus from '../../../utils/eventBus.js'
import { useAtomValue } from 'jotai'
import { timestampsAtom } from '../../../store/timestamps.store.js'

export default function TimeAndOverUnder ({
  timezone,
}) {
  const timestamps = useAtomValue(timestampsAtom)

  if (!timestamps) return null

  const actualEnd = addMilliseconds(timestamps.actual.start, timestamps.actual.duration)
  const originalEnd = addMilliseconds(timestamps.original.start, timestamps.original.duration)

  const diff = originalEnd - actualEnd

  function getFormattedTimezone () {
    if (!timezone) return 'Choose a timezone...'
    if (timezone === 'UTC') return formatTimezone(timezone, 'abbr')
    return `${formatTimezone(timezone, 'city')} (${formatTimezone(timezone, 'abbr')})`
  }

  return (
    <div className={['flex gap-6'].join(' ')}>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Time of day { timezone ? '(' + formatTimezone(timezone, 'abbr') + ')' : '' }
        </div>
        <span className={['font-mono text-sm'].join(' ')}>
          <CurrentTimeOfDay
            timezone={timezone}
          />
        </span>
      </section>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Calculated end
        </div>
        <span className="text-sm font-mono">{formatTimeOfDay(actualEnd, { timezone })}</span>
      </section>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          <p>Over / Under</p>
        </div>
        <span className='text-sm leading-6'>
          {diff >= 1000 ? <p className='text-green-500'><span className='font-mono'>{formatDurationHuman(diff)}</span> early</p> : ''}
          {diff < 1000 && diff > -1000 ? '--' : ''}
          {diff <= -1000 ? <p className='text-red-500'><span className='font-mono'>{formatDurationHuman(diff)}</span> late</p> : ''}
        </span>
      </section>
      <section>
        <div className={['px-2 text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Timezone
        </div>
        <span className={['text-sm'].join(' ')}>
          <button
            className="transition-colors px-2 h-6 text-gray-300 rounded-sm hover:bg-white/20 overflow-hidden"
            onClick={() => eventBus.$emit('openTimezoneModal')}
            title={timezone ? `${formatTimezone(timezone, 'long')}, ${formatTimezone(timezone, 'offset')}` : undefined}
          >
            {getFormattedTimezone()}
          </button>
        </span>
      </section>
    </div>
  )
}


TimeAndOverUnder.propTypes = {
  timezone: PropTypes.string,
}
