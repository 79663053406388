import { faBackwardStep, faChevronDown, faForwardStep, faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { formatCountdown, formatDurationHuman } from '../../../utils/formatTime.js'
import PropTypes from 'prop-types'
import SeekableProgressBar from './SeekableProgressBar.jsx'
import { RundownToken } from '../../../axios.js'
import { ACCESS_WRITE } from '../../../constants/rundownAccessStates.js'
import Button from '../../Button.jsx'
import { useAtomValue } from 'jotai'
import { momentAtom } from '../../../store/moment.store.js'
import { Menu, MenuItem } from '../../interactives/DropdownMenu.jsx'

// Transport States
const PRESHOW = 'PRESHOW'
const ONAIR = 'ONAIR'
const ENDED = 'ENDED'

export default function TimelineTransportControls ({
  loading,
  nextCueId,
  onStartShow,
  onEndShow,
  onResetShow,
  onNext,
  onPause,
  onPlay,
  onAddRemoveTime,
  handleNewDuration,
  readonly,
}) {
  const moment = useAtomValue(momentAtom)

  const transportState = getTransportState()
  const isLastCue = !nextCueId
  const isRunning = moment && moment.running

  function getTransportState () {
    if (moment?.ended) return ENDED
    if (moment) return ONAIR
    return PRESHOW
  }

  /**
   * Handle Keyboard shortcuts
   */
  useEffect(() => {
    function handleKeyDown (event) {
      if (event.code !== 'Space') return
      if (RundownToken.access !== ACCESS_WRITE) return

      // IF rundown is not atarted, ignore these keyboard shortcurts
      if(!moment) return


      // Check if we are in an input or a tiptap editor
      const targetElement = event.target.tagName.toLowerCase()
      if (targetElement === 'input' || targetElement === 'textarea' || hasParentWithTiptapId(event.target)) return

      switch (event.code) {
        /**
         * Space: forward to next cue or end runner
         */
        case 'Space':
          event.preventDefault()
          if (moment.running && nextCueId) onNext()
          else if (moment.running && !nextCueId) onEndShow()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onNext, onPause, onPlay, moment?.running])

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex-none flex gap-1 h-9">
        {/* Current Cue */}

        <div className="h-9 flex items-center gap-3 pr-3">
          {/* Transport State indicator */}
          {(transportState === PRESHOW &&
            <span className="h-[12px] w-[12px] rounded-full inline-block bg-gray-500"></span>
          ) || (transportState === ONAIR && isRunning &&
            <span className="h-[12px] w-[12px] rounded-full inline-block bg-red-500 animate-pulse"></span>
          ) || (transportState === ONAIR && !isRunning &&
            <FontAwesomeIcon icon={faPause} className="text-gray-400" />
          ) || (transportState === ENDED &&
            <FontAwesomeIcon icon={faStop} className="text-gray-400" />
          )}

          <span className="font-mono text-xl whitespace-nowrap">
            { formatCountdown(moment?.left || 0) }
          </span>
        </div>

        {/* Subtract Time */}
        <div className='flex'>
          <Button
            className="text-base rounded-r-none"
            text="-1m"
            toolTip="Shorten 1 minute"
            loading={loading}
            colour="dark"
            onClick={() => onAddRemoveTime(-60000)}
            wrapperClass="flex-none"
            disabled={readonly || transportState !== ONAIR}
          />
          <Menu
            className="rounded-l-none border-l-2 border-gray-800 !px-2"
            loading={loading}
            icon={faChevronDown}
            disabled={readonly || transportState !== ONAIR}
          >
            {[-1800000, -600000, -300000, -60000, -30000, -10000, -1000].map((ms) => {
              return (
                <MenuItem
                  key={ms}
                  label={'-' + formatDurationHuman(ms).trim()}
                  onClick={() => onAddRemoveTime(ms)}
                />
              )
            })}
          </Menu>
        </div>

        {/* Pause / Play */}
        <Button
          onClick={isRunning ? onPause : onPlay }
          colour="dark"
          wrapperClass="flex-none"
          buttonClass="!w-24"
          loading={loading}
          disabled={readonly || loading || transportState !== ONAIR}
          icon={isRunning ? faPause : faPlay}
          toolTip={isRunning ? 'Pause' : 'Resume'}
        />

        {/* Start / Next / End / Reset */}
        {(transportState === PRESHOW &&
          <Button
            onClick={onStartShow}
            text="Start show"
            toolTip="Start the show, will start timer on selected or first cue."
            colour="red"
            wrapperClass="grow"
            buttonClass="w-full"
            loading={loading}
            disabled={readonly || loading}
          />
        ) || (transportState === ONAIR && !isLastCue &&
          <Button
            onClick={onNext}
            text="Next cue"
            toolTip="Advance show to the next selected cue."
            icon={faForwardStep}
            colour="dark"
            wrapperClass="grow"
            buttonClass="w-full"
            loading={loading}
            disabled={readonly || loading}
          />
        ) || (transportState === ONAIR && isLastCue &&
          <Button
            onClick={onEndShow}
            text="End show"
            toolTip="End the show, stop timer and keep elapsed times on screen."
            icon={faStop}
            colour="dark"
            wrapperClass="grow"
            buttonClass="w-full"
            loading={loading}
            disabled={readonly || loading}
          />
        ) || (transportState === ENDED &&
          <Button
            onClick={onResetShow}
            text="Reset show"
            toolTip="End the show, stop timer and keep elapsed times on screen."
            icon={faBackwardStep}
            colour="dark"
            wrapperClass="grow"
            buttonClass="w-full"
            loading={loading}
            disabled={readonly || loading}
          />
        )}

        {/* Add Time */}
        <div className='flex'>
          <Button
            className="text-base rounded-r-none"
            text="+1m"
            toolTip="Extend 1 minute"
            loading={loading}
            colour="dark"
            onClick={() => onAddRemoveTime(60000)}
            wrapperClass="flex-none"
            disabled={readonly || transportState !== ONAIR}
          />
          <Menu
            className="rounded-l-none border-l-2 border-gray-800 !px-2"
            loading={loading}
            icon={faChevronDown}
            disabled={readonly || transportState !== ONAIR}
          >
            {[1800000, 600000, 300000, 60000, 30000, 10000, 1000].map((ms) => {
              return (
                <MenuItem
                  key={ms}
                  label={'+' + formatDurationHuman(ms).trim()}
                  onClick={() => onAddRemoveTime(ms)}
                />
              )
            })}
          </Menu>
        </div>
      </div>
      <div className="flex gap-3">
        {/* Progress Bar */}
        <SeekableProgressBar
          moment={moment}
          handleNewDuration={handleNewDuration}
          readonly={readonly}
        />
      </div>
    </div>
  )
}

TimelineTransportControls.propTypes = {
  loading: PropTypes.bool,
  nextCueId: PropTypes.string,
  onStartShow: PropTypes.func.isRequired,
  onEndShow: PropTypes.func.isRequired,
  onResetShow: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onAddRemoveTime: PropTypes.func.isRequired,
  handleNewDuration: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}

function hasParentWithTiptapId (element) {
  let currentElement = element
  while (currentElement.parentElement) {
    currentElement = currentElement.parentElement
    if (currentElement.id === 'tiptap') {
      return true
    }
  }
  return false
}
