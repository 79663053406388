import PropTypes from 'prop-types'
import { LargeTimeOfDay } from './RundownOutputHeroCue'
import { CELL_SELECT_COLORS } from '@rundown-studio/consts'
import _indexOf from 'lodash/indexOf'
import ScrollIntoView from '../ScrollIntoView'
import { useState } from 'react'

const MARGIN_DEFAULT = 5
const TEXT_SIZE_DEFAULT = 20

export default function RundownOutputPrompter ({ rundown, cues, cells, runner, visibleColumn, controlsVisible }) {
  const [marginSize, setMarginSize] = useState(MARGIN_DEFAULT)
  const [textSize, setTextSize] = useState(TEXT_SIZE_DEFAULT)

  return (
    <div className='relative h-full'>
      {controlsVisible && <div className='px-4 py-2 sm:h-10 rounded-lg bg-gray-800/90 flex flex-col sm:flex-row items-center justify-center gap-3 fixed bottom-14 right-3 z-10'>
        <label className='flex gap-2'>
          <span className='text-right'>Margin • <span className='font-mono text-sm'>{marginSize}</span></span>
          <input
            type='range'
            min={0}
            max={40}
            step={1}
            value={marginSize}
            onChange={(e)=>setMarginSize(e.target.value)}
          />
        </label>
        <label className='flex gap-2'>
          <span className='text-right'>Font size • <span className='font-mono text-sm'>{textSize}</span></span>
          <span></span>
          <input
            type='range'
            min={10}
            max={100}
            step={1}
            value={textSize}
            onChange={(e)=>setTextSize(parseFloat(e.target.value))}
          />
        </label>
      </div>}
      <div
        style={{
          marginLeft: `${marginSize}vw`,
          marginRight: `${marginSize}vw`,
        }}
      >
        <PrompterContent
          rundown={rundown}
          cues={cues}
          cells={cells}
          runner={runner}
          visibleColumn={visibleColumn}
          textSize={textSize}
        />
      </div>
    </div>
  )

}

RundownOutputPrompter.propTypes = {
  rundown: PropTypes.object,
  cues: PropTypes.object,
  cells: PropTypes.object,
  runner: PropTypes.object,
  visibleColumn: PropTypes.object,
  controlsVisible: PropTypes.bool,
}

const PrompterContent = ({ rundown, cues, cells, runner, visibleColumn, textSize }) => {
  const currentCue = runner?.timesnap.cueId ? cues[runner.timesnap.cueId] : null
  // Partial State: When rundown is not started
  if (!currentCue) return (
    <div className='h-full flex flex-col items-center justify-center'>
      <LargeTimeOfDay className='opacity-100' timezone={rundown.timezone} />
      <p className='text-xl opacity-70'>Waiting for the rundown to start</p>
    </div>
  )

  // Partial State: When user did not select a column for the prompter
  if(!visibleColumn) return (
    <div className='h-full flex flex-col items-center justify-center'>
      <p className='text-xl opacity-70'>No script column selected</p>
    </div>
  )

  const cell = cells?.[currentCue.id]

  // Partial State: No cell exists for the currently active cue
  if(!cell) return null

  // Ideal State: A column has been selected
  return (
    <div className="relative ProseMirror ProseMirrorOutput h-full">
      {/* If the user has scrolled down, scroll them back to the top */}
      {/* KEY ensures that the ScrollIntoView component is re-rendered */}
      <ScrollIntoView key={cell.id} />

      {visibleColumn?.type === 'select' &&
        <div
          className='pt-10'
        >
          <div
            style={{
              backgroundColor: CELL_SELECT_COLORS[_indexOf(visibleColumn.options, cell?.content.selected)],
            }}
            className={[
              'text-6xl w-min whitespace-nowrap rounded-full px-5 py-4 text-center mx-auto',
            ].join(' ')}
            dangerouslySetInnerHTML={{ __html: cell?.content?.selected }}></div>
        </div>
      }

      {visibleColumn?.type === 'richtext' &&
      <div
        style={{
          fontSize: `${textSize}px`,
          '--output-text-size': `${textSize}px`,
        }}
        className={[
          'pt-10 px-10 pb-20 w-full leading-[1.4]',
        ].join(' ')}
        dangerouslySetInnerHTML={{ __html: cell?.content?.text }}
      ></div>
      }
    </div>
  )
}

PrompterContent.propTypes = {
  rundown: PropTypes.object,
  cues: PropTypes.object,
  cells: PropTypes.object,
  runner: PropTypes.object,
  visibleColumn: PropTypes.object,
  textSize: PropTypes.number,
}